<template>
  <div>
    <v-switch
      v-model="switchFlg"
      dense
      @change="askPermissionAndSwitchSubscription"
    />
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'

export default {
  name: 'NotificationPermissionBtn',
  props: {
    value: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  setup(props, { emit }) {
    const switchFlg = ref(!!props.value?.subscriptionPayload?.endpoint)
    const urlBase64ToUint8Array = base64String => {
      const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
      const base64 = (base64String + padding)
        .replace(/-/g, '+')
        .replace(/_/g, '/')

      const rawData = window.atob(base64)
      const outputArray = new Uint8Array(rawData.length)

      for (let i = 0; i < rawData.length; i += 1) {
        outputArray[i] = rawData.charCodeAt(i)
      }

      return outputArray
    }
    const unsubscribeFromPush = async () => {
      const registration = await navigator.serviceWorker.getRegistration()
      const subscription = await registration.pushManager.getSubscription()
      await subscription.unsubscribe()
    }

    const askPermissionAndSwitchSubscription = () => {
      return new Promise((resolve, reject) => {
        const permissionResult = Notification.requestPermission(result => {
          resolve(result)
        })

        if (permissionResult) {
          permissionResult.then(resolve, reject)
        }
      }).then(permissionResult => {
        if (permissionResult !== 'granted') {
          throw new Error("We weren't granted permission.")
        } else if (permissionResult === 'granted') {
          if (!switchFlg.value) {
            unsubscribeFromPush()

            return emit('cancel')
          }

          return navigator.serviceWorker.getRegistration().then(registration => {
            const subscribeOptions = {
              userVisibleOnly: true,
              applicationServerKey: urlBase64ToUint8Array(
                process.env.VUE_APP_WEB_PUSH_VAPID_PUBLIC_KEY,
              ),
            }

            return registration.pushManager.subscribe(subscribeOptions).then(pushSubscription => {
              const payload = JSON.parse(JSON.stringify(pushSubscription))
              const subscriptionPayload = {
                endpoint: payload.endpoint,
                ...payload.keys,
              }
              emit('update', subscriptionPayload)
            })
          })
        }

        return true
      })
    }

    return {
      switchFlg,
      // methods
      askPermissionAndSwitchSubscription,
    }
  },
}
</script>
